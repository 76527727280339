@use "sass:math";
// @import '~ngx-sharebuttons/themes/default';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

$md-radio-checked-color: var(--rx-primary-color);
$md-radio-border-color: var(--rx-primary-color-light);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.md-radio {
  padding: 5px 10px;
  margin-bottom: 5px;

  &.md-radio-inline {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;

    &:checked+label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }

    &:checked+label:after {
      transform: scale(1);
    }
  }

  label {
    display: block;
    height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }

    &:after {
      top: math.div($md-radio-size, 2) - math.div($md-radio-checked-size, 2);
      left: math.div($md-radio-size, 2) - math.div($md-radio-checked-size, 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}

@-webkit-keyframes blinker_success_new {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

@-moz-keyframes blinker_success_new {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

@-o-keyframes blinker_success_new {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

@keyframes blinker_success_new {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

.blinker_success_new {
  -webkit-animation: blinker_success_new 0.7s ease-out 0s;
  -moz-animation: blinker_success_new 0.7s ease-out 0s;
  -o-animation: blinker_success_new 0.7s ease-out 0s;
  animation: blinker_success_new 0.7s ease-out 0s;
}

@-webkit-keyframes blinker_success {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

@-moz-keyframes blinker_success {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

@-o-keyframes blinker_success {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

@keyframes blinker_success {
  10% {
    background-color: var(--success);
  }

  100% {
    background-color: inherit;
  }
}

.blinker_success {
  -webkit-animation: blinker_success 0.7s ease-out 0s;
  -moz-animation: blinker_success 0.7s ease-out 0s;
  -o-animation: blinker_success 0.7s ease-out 0s;
  animation: blinker_success 0.7s ease-out 0s;
}

@-webkit-keyframes blinker_danger {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

@-moz-keyframes blinker_danger {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

@-o-keyframes blinker_danger {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

@keyframes blinker_danger {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

.blinker_danger {
  -webkit-animation: blinker_danger 0.7s ease-out 0s;
  -moz-animation: blinker_danger 0.7s ease-out 0s;
  -o-animation: blinker_danger 0.7s ease-out 0s;
  animation: blinker_danger 0.7s ease-out 0s;
}

@-webkit-keyframes blinker_danger_new {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

@-moz-keyframes blinker_danger_new {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

@-o-keyframes blinker_danger_new {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

@keyframes blinker_danger_new {
  10% {
    background-color: var(--danger);
  }

  100% {
    background-color: inherit;
  }
}

.blinker_danger_new {
  -webkit-animation: blinker_danger_new 0.7s ease-out 0s;
  -moz-animation: blinker_danger_new 0.7s ease-out 0s;
  -o-animation: blinker_danger_new 0.7s ease-out 0s;
  animation: blinker_danger_new 0.7s ease-out 0s;
}

@-webkit-keyframes blinker_info_new {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

@-moz-keyframes blinker_info_new {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

@-o-keyframes blinker_info_new {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

@keyframes blinker_info_new {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

.blinker_info_new {
  -webkit-animation: blinker_info_new 0.7s ease-out 0s;
  -moz-animation: blinker_info_new 0.7s ease-out 0s;
  -o-animation: blinker_info_new 0.7s ease-out 0s;
  animation: blinker_info_new 0.7s ease-out 0s;
}

@-webkit-keyframes blinker_info {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

@-moz-keyframes blinker_info {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

@-o-keyframes blinker_info {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

@keyframes blinker_info {
  10% {
    background-color: var(--info);
  }

  100% {
    background-color: inherit;
  }
}

.blinker_info {
  -webkit-animation: blinker_info 0.7s ease-out 0s;
  -moz-animation: blinker_info 0.7s ease-out 0s;
  -o-animation: blinker_info 0.7s ease-out 0s;
  animation: blinker_info 0.7s ease-out 0s;
}

// * {
//   font-family: "Verdana", sans-serif;
// }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--white);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--white);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--white);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--white);
}

a {
  color: var(--rx-primary-color);
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: var(--rx-primary-color-dark);
  text-decoration: underline;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
}

body {
  overflow: auto;
  color: var(--rx-text);
  font-size: 0.8rem;
  font-family: "Verdana", sans-serif !important;
  background-color: var(--rx-bg-default);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1;
  margin-top: 0.65em;
  margin-bottom: 0.65em;
}

.main-wrapper {
  height: 100vh;
}

.rx-navbar {
  height: 60px;
  position: fixed;
  background-color: var(--rx-bg-topbar);
  border-bottom: 1px solid var(--rx-bg-topbar-border);
  z-index: 109;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  .rx-navbar-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    .brand-panel {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      .brand-logo {
        display: flex;
        margin-left: 10px;
        margin-right: 10px;
        max-width: 200px;
        min-width: 150px;
        align-items: center;
        cursor: pointer;
        justify-content: center;

        img {
          max-width: 200px;
          max-height: 35px;
        }
      }
    }

    .rx-nav-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      height: 100%;

      .rx-nav-pill {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0px 8px;

        a {
          display: flex;
          align-items: center;
          height: 100%;
          color: var(--rx-text);

          &.active {
            color: var(--rx-primary-color) !important;
          }

          &:hover {
            color: var(--rx-primary-color) !important;
          }
        }
      }
    }
  }

  .rx-navbar-end {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .rx-nav-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      height: 100%;

      .rx-nav-pill {
        height: 100%;
        display: flex;
        align-items: center;
        margin: 0px 8px;

        a,
        .alink {
          display: flex;
          align-items: center;
          height: 100%;
          color: var(--rx-text);
          cursor: pointer;

          &.active {
            color: var(--rx-primary-color) !important;
          }

          &:hover {
            color: var(--rx-primary-color) !important;
          }
        }
      }
    }
  }

  .rx-nav-svg {
    height: 18px;
    width: 18px;

    &.svg-color {
      fill: var(--rx-primary-color);
    }
  }

  .svg-bg {
    height: 30px !important;
    width: 30px !important;
  }

  .top-options {
    display: flex;
    align-items: center;
    height: 100%;

    .side-menu-bar {
      display: none;
      height: 100%;

      .rx-nav-svg {
        fill: var(--rx-text);
      }

      &:hover .rx-nav-svg {
        fill: var(--rx-primary-color);
      }
    }

    .settings_mob {
      display: none;
      height: 100%;

      .rx-nav-svg {
        fill: var(--rx-text);
      }

      &:hover .rx-nav-svg {
        fill: var(--rx-primary-color);
      }
    }
  }
}

.side-nav-panel {
  display: none;
}

.rx-side-navbar {
  position: fixed;
  right: -350px;
  height: 100vh;
  background-color: var(--rx-bg-secondary);
  width: 350px;
  z-index: 1040;
  transition: all 0.5s ease 0s;

  .menu-list {
    height: calc(100vh - 112px);
    overflow: auto;

    .menu-list-pill {
      padding: 0.5rem 1rem;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        background-color: var(--rx-bg-default);
      }

      &.active {
        color: var(--rx-btn-color);
        background-color: var(--rx-primary-color);
      }
    }
  }
}

.rx-side-navbar-backdrop {
  position: fixed;
  background-color: var(--rx-bg-default-fixed);
  opacity: 0.7 !important;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.open-side-navbar {
  right: 0px;
}

.rx-svg-primary {
  fill: var(--rx-primary-color);
}

.rx-svg-primary path {
  fill: var(--rx-primary-color);
}

.features {
  display: flex;
  align-items: center;
  height: 100%;

  .rx-nav-svg path {
    fill: var(--rx-text);
  }

  i {
    fill: var(--rx-text);
  }

  .featuresBtn {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    min-width: 180px;
  }

  &:hover .featuresDrop {
    display: block;
  }

  &:hover {
    .rx-nav-svg path {
      fill: var(--rx-primary-color);
    }
  }

  &:hover i {
    transition: all 0.1s ease 0s;
    transform: rotateX(180deg);
  }

  .featuresDrop {
    display: none;
    position: absolute;
    padding: 10px;
    top: 59px;
    left: 0;
    width: 700px;
    color: var(--rx-text) !important;
    background-color: var(--rx-bg-secondary) !important;
    border: 1px solid var(--rx-bg-topbar-border) !important;

    .featuresDrop-grid {
      display: flex;
      flex-wrap: wrap;
      max-width: 100vw;
      margin: 0px -5px;
    }

    .features-box {
      width: 50%;
      padding: 0px 5px;
      margin-bottom: 10px;

      .feature-pill {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 0.25rem;
        color: var(--rx-text);
        cursor: pointer;

        &:hover {
          color: var(--rx-btn-color);
          background-color: var(--rx-primary-color) !important;

          .rx-nav-svg.svg-color {
            fill: var(--white);
          }

          .rx-nav-svg path {
            fill: var(--white);
          }
        }

        &.active {
          color: var(--rx-btn-color);
          background-color: var(--rx-primary-color) !important;

          .rx-nav-svg.svg-color {
            fill: var(--white);
          }

          .rx-nav-svg path {
            fill: var(--white);
          }
        }

        .feature-heading {
          font-size: 15px;
        }

        .feature-subheading {
          font-size: 12px;
        }
      }
    }

    // .feature-pill:last-child {
    //   margin-bottom: 0px;
    // }
  }
}

.moreMenu {
  display: flex;
  align-items: center;
  height: 100%;

  i {
    fill: var(--rx-text);
  }

  .moreMenuBtn {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }

  &:hover .moreMenuDrop {
    display: block;
  }

  &:hover .moreMenuBtn span {
    color: var(--rx-primary-color);
  }

  &:hover i {
    transition: all 0.1s ease 0s;
    transform: rotateX(180deg);
  }

  .moreMenuDrop {
    display: none;
    position: absolute;
    padding: 10px;
    top: 59px;
    right: 0;
    min-width: 190px;
    color: var(--rx-text) !important;
    background-color: var(--rx-bg-secondary) !important;
    border: 1px solid var(--rx-bg-topbar-border) !important;

    .moreMenu-pill {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 0.25rem;
      margin-bottom: 10px;
      color: var(--rx-text);
      cursor: pointer;

      &:hover {
        color: var(--rx-btn-color);
        background-color: var(--rx-primary-color) !important;

        .rx-nav-svg.svg-color path {
          fill: var(--white);
        }

        .rx-nav-svg.svg-color polygon {
          fill: var(--white);
        }
      }

      .moreMenu-heading {
        font-size: 15px;
      }

      .moreMenu-subheading {
        font-size: 12px;
      }

      .rx-nav-svg path {
        fill: var(--rx-primary-color);
      }
    }

    .moreMenu-pill:last-child {
      margin-bottom: 0px;
    }
  }
}

.collapse-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.collapse-menu-list {
  margin-top: 1rem;
  display: none;
}

.active-collapse {
  display: block;
}

.collapse-menu-pill {
  padding: 0.5rem 1rem;
  font-size: 16px;
  cursor: pointer;
}

.active-collp {
  transition: all 0.1s ease 0s;
  transform: rotateX(180deg);
}

.position-relative {
  position: relative !important;
}

.grecaptcha-badge {
  display: none !important;
  // width: 256px;
  //   height: 60px;
  //   display: block;
  //   transition: left 0.3s ease 0s !important;
  //   position: fixed;
  //   bottom: 14px;
  //   left: 0px !important;
  //   box-shadow: grey 0px 0px 5px;
  //   border-radius: 2px;
  //   overflow: hidden;
  //   z-index: 999;
  //   right: 0 !important;
}

// .connection-modal {
//   position: absolute;
//   z-index: 1050;
//   background: var(--rx-bg-secondary);
//   border: 1px solid var(--rx-border-color);
//   padding: 1rem;
//   left: 50%;
//   margin-left: -200px;
//   width: 400px;
//   text-align: center;
//   box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
// }

.rx-navbar > div > div div.top-options {
  display: flex;
  align-items: center;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.15rem;
}

.tdfy-header-btn {
  margin-right: 10px;
}

.tdfy_xd144 {
  font-size: 10px;
  text-align: center;
  color: var(--rx-text);
  font-weight: 500;
}

.Bal_section {
  display: flex;
  flex-direction: column;
  color: var(--rx-bar-text);
  padding: 0.3rem 1rem;
  border: 2px solid var(--rx-bg-topbar-border);
  background: var(--rx-card-bg);
  border-radius: 5px;
  margin-right: 5px;
  width: 50%;
}

.Bal_section:last-child {
  margin-right: 0px;
}

pre {
  -webkit-overflow-scrolling: touch;
  background-color: var(--rx-bg-secondary);
  color: var(--rx-text);
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  white-space: pre;
  word-wrap: normal;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

pre code {
  background-color: var(--rx-bg-secondary);
  color: currentColor;
  font-size: 1em;
  padding: 0;
}

.setting_web {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.content-wrapper {
  padding-top: 60px;
  min-height: 100vh;
  /* overflow: auto; */
}

// ::-webkit-scrollbar-track {
//   background: var(--rx-bg-secondary);
//   border: 1px solid var(--rx-border-color);
// }

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--rx-border-color);
  cursor: pointer;
  border-radius: 5px;
}

.tbl-loader {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-top: 0px !important;
}

.tbl-no-data {
  width: 100%;
  height: 100px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.content-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.x-drop.focus,
// .x-drop:focus {
//   box-shadow: 0 0 0 0.2rem rgb(32 158 64 / 65%) !important;
// }

.grd-h,
.grd-v {
  background-color: var(--rx-bg-secondary);
  padding: 20px;
  color: var(--rx-text);
  border: 1px solid var(--rx-border-color);
  -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.grd-h,
.grd-v:hover {
  border: 1px solid var(--rx-primary-color);
}

.x__d48pDa {
  width: 100%;
  overflow-x: hidden;
}

.page-item.disabled .page-link {
  color: var(--rx-text);
  pointer-events: none;
  cursor: auto;
  background-color: var(--rx-bg-default);
  border-color: var(--rx-border-color);
}

.page-item.active .page-link {
  z-index: 1;
  color: var(--rx-text);
  background-color: var(--rx-border-color);
  border-color: var(--rx-border-color);
}

.page-link {
  position: relative;
  display: block;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  margin-left: -1px;
  line-height: 1.25;
  cursor: pointer;
  color: var(--rx-text);
  background-color: var(--rx-bg-default);
  border: 1px solid var(--rx-border-color);
}

.page-link:hover {
  z-index: 2;
  color: var(--rx-text);
  text-decoration: none;
  background-color: var(--rx-border-color);
  border-color: var(--rx-border-color);
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin: 8px;
}

.market-panel {
  padding: 20px;
  background: var(--rx-bg-secondary);
  border: 1px solid var(--rx-border-color);
}

.market_tb_header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.short_market_tb_header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
}

.market_tb_header .title {
  -ms-flex-order: 1;
  order: 1;
  -ms-flex: none;
  flex: none;
  margin-right: 24px;
}

/* ---------------------------------------------------------------------- */

div.tdfy-marketlist .tdfy-lg-row .tdfy-lg-col {
  /* flex: 1; */
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 0px 10px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

div.tdfy-marketlist .tdfy-lg-row .tdfy-lg-col a,
div.tdfy-marketlist .tdfy-lg-row .tdfy-lg-col label {
  margin: 0px !important;
}

div.tdfy-marketlist .tdfy-lg-row .tdfy-lg-col.sortable .sortIcon {
  fill: var(--rx-text);

  &.active {
    fill: var(--rx-primary-color);
  }
}

.sortable .sortIcon {
  fill: var(--rx-text);

  &.active {
    fill: var(--rx-primary-color);
  }
}

div.tdfy-marketlist > .tdfy-marketlist-header > .tdfy-lg-row div {
  // flex: 1;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 10px;
  color: var(--rx-table-header-color) !important;
}

div.tdfy-marketlist
  .tdfy-lg-row
  div.tdfy-marketlist-n
  div.tdfy-marketlist-n-nn {
  display: flex;
  flex-direction: column;
}

div.tdfy-marketlist
  .tdfy-lg-row
  div.tdfy-marketlist-n
  div.tdfy-marketlist-n-nn
  > label {
  font-size: 14px;
  margin-bottom: 2px;
}

div.tdfy-marketlist .tdfy-marketlist-body .tdfy-lg-row:nth-child(2n) {
  background: rgba(255, 255, 255, 0.01);
}

div.tdfy-marketlist.tdfy-marketlist-body .tdfy-lg-row:hover {
  background: var(--rx-table-hover) !important;
}

div.tdfy-marketlist .tdfy-marketlist-header .tdfy-lg-row:hover {
  background: var(--rx-table-header) !important;
}

div.tdfy-marketlist .tdfy-marketlist-body .tdfy-lg-row {
  color: var(--rx-text);
  border-top: 1px solid var(--rx-table-border-color);
}

div.tdfy-marketlist .tdfy-lg-row {
  width: 100%;
  height: 38px;
  min-height: 38px;
  display: flex;
  align-items: stretch;
  color: var(--rx-table-header-color);
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

div.tdfy-marketcoinlist {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.tdfy-marketlist-header {
  background-color: var(--rx-table-header);
}

div.tdfy-marketlist > div > div > div.tdfy-marketlist-cellnumber {
  display: flex;
  justify-content: flex-end;
}

/* ----------------------------------------------------------------------------------------- */

.sk-slider-data {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
}

.sk-slider-data::-webkit-scrollbar {
  display: none;
}

.market-tabs {
  flex-basis: 100%;
  -ms-flex-order: 2;
  order: 2;
}

.market_tabs_btn:last-child {
  margin-right: 0 !important;
}

.market_tabs_btn {
  display: inline-block;
  margin: 0rem 0.25rem 0.5rem 0rem;
}

.market-tabs .header-mkt {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 100%;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow: scroll;
  -ms-overflow-style: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
}

.market-tabs .header-mkt::-webkit-scrollbar {
  display: none;
}

.market_tabs_btn .btn-tabs {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  color: var(--rx-text);
  background: var(--rx-bg-secondary);
  border: 1px solid var(--rx-border-color);
}

.market_tabs_btn .btn-tabs:hover {
  border: 1px solid var(--rx-primary-color);
}

.__mkt_active {
  background: var(--rx-primary-color) !important;
  color: var(--rx-btn-color) !important;
  border: 1px solid var(--rx-primary-color) !important;
}

.marketmore_options {
  order: 3;
}

.tbshow {
  background-color: var(--rx-table-inner-bg) !important;
}

.deptTopBal > div > div {
  flex-grow: 1;
  background: var(--rx-bg-secondary) !important;
  border: 1px solid var(--rx-border-color);
  padding: 0.5rem;
  text-align: center;
  margin: 0.2rem 0rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--rx-text) !important;
  background-color: var(--rx-bg-secondary) !important;
  background-clip: padding-box;
  border: 1px solid var(--rx-border-color) !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select {
  color: var(--rx-text) !important;
  background-color: var(--rx-bg-secondary) !important;
  border: 1px solid var(--rx-border-color) !important;
  font-size: 0.9rem;
  height: 38px;
}

._3NfYI0Ta {
  height: 100%;
}

._ldijeo > p {
  margin: 0.5rem 0;
  line-height: 1.4;
}

._TFY1456 {
  font-size: 16px;
  letter-spacing: 1px;
  color: var(--rx-text);
}

._TFY1457 {
  font-size: 12px;
  color: var(--rx-text);
}

._TFY1458 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 6px 0;
  font-size: 20px;
  line-height: 10px !important;
}

._1eScc6gB {
  font-size: 14px;
  line-height: 28px;
  color: var(--rx-text);
}

._1eScc6gB:before {
  content: " ";
  white-space: pre;
}

*:focus,
*:active {
  outline: none !important;
}

.market_wrapper {
  width: 100%;
  padding: 5px;
}

.x_wrapper {
  width: 100%;
  // min-height: 100vh;
  padding: 10px 15px 10px 15px;
}

.mv-wrapper {
  padding: 10px;
}

.copyright_panel {
  text-align: left;
}

.copyright_panel p {
  margin-bottom: 0rem;
}

.prx_1e45 {
  width: 100%;
  height: 100%;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

._prxd14 {
  width: 310px;
  flex: 1 0 auto;
  max-width: 350px;
}

.__djieo12 {
  width: 100%;
  height: calc(50vh - 35px);
  padding: 5px;
}

.Order-book-section {
  width: 100%;
  height: calc(65vh - 65px);
  padding: 5px;
}

.order-book-row {
  position: relative;
  // border-bottom: 1px solid var(--rx-card-bg);
}

// .order-book-row:last-child {
//   border-bottom: 0px;
// }

.trade-fee-section {
  width: 100%;
  height: 34vh;
  padding: 5px;
}

// .main-chart-section {
//   width: 100%;
//   height: calc(65vh - 65px);
//   padding: 5px;
// }

.all-orders-section {
  width: 100%;
  height: 34vh;
  padding: 5px;
}

.order-form-section {
  width: 100%;
  height: calc(99vh - 65px);
  padding: 5px;
}

.small-chart-section {
  width: 100%;
  height: 21vh;
  padding: 5px;
}

.charts-tile {
  flex-wrap: wrap;
  display: flex;
}

._xdCard {
  background: var(--rx-card-bg);
  border: 1px solid var(--rx-border-color);
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}

._xdCard_header {
  padding: 5px 10px;
  background: var(--rx-bg-secondary);
  border-bottom: 1px solid var(--rx-border-color);
}

._xdCard_body {
  padding: 5px 10px;
  background: var(--rx-card-bg);
}

._xdCard_header h6 {
  margin: 0px;
  font-size: 14px;
  line-height: 34px;
  height: 34px;
}

.x_sidebar .x_sidebar_ul li:hover {
  background-color: var(--rx-primary-color);
  color: var(--rx-btn-color);
}

.x_sidebar .x_sidebar_ul li a:hover {
  background-color: var(--rx-primary-color);
  color: var(--rx-btn-color);
}

.is-active {
  background-color: var(--rx-primary-color) !important;
  color: var(--rx-btn-color) !important;
}

.x_sidebar .x_sidebar_ul > li.is-active > .iconbox > span:last-child {
  color: var(--white);
}

.x_sidebar .x_sidebar_ul > li.is-active > .iconbox > span > svg {
  fill: var(--white);
}

.x_sidebar .x_sidebar_ul > li > a.is-active > .iconbox > span > svg {
  fill: var(--white);
}

// .is-active{
//     background-color: var(--rx-primary-color) !important;
//     color: var(--white) !important;
// }

.x_sidebar .x_sidebar_ul > li > .is-active > span:last-child {
  color: var(--white) !important;
}

.x_sidebar .x_sidebar_ul > li > .is-active > span > svg {
  fill: var(--white) !important;
}

._2gNK-pDK {
  max-width: calc(100% - 310px);
  display: flex;
}

.buy_sell {
  position: relative;
}

.buy_sell .nav-link {
  display: block;
  padding: 0.3rem 1rem;
}

.btns_buy_sell {
  display: flex;
  margin-bottom: 10px;
}

.btns_buy_sell .btn {
  width: 100%;
  border-radius: 0px !important;
}

.btns_buy_sell .btn:last-child {
  width: 100%;
  margin-left: 5px;
}

.change_up {
  color: var(--success);
  font-weight: 400;
}

.change_up_plus {
  color: var(--success);
  font-weight: 400;
}

.change_down {
  color: var(--danger);
  font-weight: 400;
}

.mk-height {
  max-height: 100vh;
  position: relative;
}

.btns_opt_list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.btns_opt_list .opt_btns {
  padding: 5px 10px;
  font-size: 12px;
  flex-grow: 1;
  text-align: center;
  color: var(--rx-text);
  background: var(--rx-card-bg);
  cursor: pointer;
  border: 1px solid var(--rx-tabs-bg);
  border-bottom: 2px solid var(--rx-tabs-bg);
}

.btns_opt_list .opt_btns:hover {
  color: var(--rx-primary-color);
}

.btns_opt_list .opt_btns.active,
.btns_opt_list .opt_btns.active:hover,
.btns_opt_list .opt_btns.active:focus {
  background: var(--rx-tabs-bg);
  border-bottom: 2px solid var(--rx-tabs-bg);
  color: var(--white);
}

._prxd15 {
  flex-grow: 1;
  max-width: 50%;
}

#sellbook .tb_row > div:last-child {
  padding-right: 10px;
}

#buybook .tb_row > div:last-child {
  padding-right: 10px;
}

a:not(.collapsed) .rotate-icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

a:not(.collapsed) .rotate-icon2 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* ---------------------- table format --------------------- */

div.tdfy_data_list .tb_row > div:last-child {
  padding-right: 15px;
}

/* div.tdfy_data_list_vd .tb_row > div:last-child{
    padding-right: 15px;
} */
div.tdfy_data_list .tdfy_data_list_header .tb_row > div:last-child {
  padding-right: 16px;
}

div.tdfy_data_list_vd .tdfy_data_list_header .tb_row > div:last-child {
  padding-right: 16px;
}

div.tdfy_data_list .tb_row > div {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0px 10px;
}

div.tdfy_data_list_vd .tb_row > div {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0px 10px;
  font-size: 0.73rem;
}

div.tdfy_data_list .tb_row > div a,
div.tdfy_data_list .tb_row div label {
  margin: 0px !important;
}

div.tdfy_data_list_vd .tb_row > div a,
div.tdfy_data_list_vd .tb_row div label {
  margin: 0px !important;
}

div.tdfy_data_list > .tdfy_data_list_header > div > div > div {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0px 10px;
}

div.tdfy_data_list_vd > .tdfy_data_list_header > div > div > div {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0px 10px;
}

div.tdfy_data_list .tb_row > div.tdfy_data_list-n > div.tdfy_data_list-n-nn {
  display: flex;
  flex-direction: column;
}

div.tdfy_data_list_vd .tb_row > div.tdfy_data_list-n > div.tdfy_data_list-n-nn {
  display: flex;
  flex-direction: column;
}

div.tdfy_data_list
  .tb_row
  > div.tdfy_data_list-n
  > div.tdfy_data_list-n-nn
  > label {
  font-size: 14px;
  margin-bottom: 2px;
}

div.tdfy_data_list_vd
  .tb_row
  > div.tdfy_data_list-n
  > div.tdfy_data_list-n-nn
  > label {
  font-size: 14px;
  margin-bottom: 2px;
}

/* div.tdfy_data_list .tb_row:nth-child(2n) {
    background: rgba(255,255,255,0.01);
} */
div.tdfy_data_list .tb_row:hover {
  background: var(--rx-table-hover);
}

div.tdfy_data_list_vd .tb_row:hover {
  background: var(--rx-table-hover);
}

div.tdfy_data_list .tb_row {
  position: relative;
  width: 100%;
  height: 28px;
  min-height: 28px;
  display: flex;
  align-items: stretch;
  border-top: 1px solid var(--rx-border-color);
  color: var(--rx-text);
  cursor: pointer;
  z-index: 1;
}

div.tdfy_data_list_vd .tb_row {
  position: relative;
  width: 100%;
  height: 23px;
  min-height: 23px;
  display: flex;
  align-items: stretch;
  // border-top: 1px solid var(--rx-table-border-color);
  color: var(--rx-text);
  cursor: pointer;
  z-index: 1;
}

div.tdfy_data_list_vd .tb_row:first-child {
  border-top: 0px;
}

div.tdfy_data_list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

div.tdfy_data_list_vd {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.tdfy_data_list_header {
  background-color: var(--rx-table-header);
}

div.tdfy_data_list .tb_row > div.tdfy_data_list_right {
  display: flex;
  justify-content: flex-end;
}

div.tdfy_data_list_vd .tb_row > div.tdfy_data_list_right {
  display: flex;
  justify-content: flex-end;
}

div.tdfy_data_list .tb_row > div.tdfy_data_list_center {
  display: flex;
  justify-content: center;
}

div.tdfy_data_list_vd .tb_row > div.tdfy_data_list_center {
  display: flex;
  justify-content: center;
}

/* ---------------------- table format --------------------- */

/* ---------------------- big table format ----------------- */

.SimpleBar {
  overflow: auto;
}

div.tdfy_bg_data_list .tb_row > div:last-child {
  padding-right: 15px;
}

div.tdfy_bg_data_list .tb_row > div {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 0px 10px;
}

div.tdfy_bg_data_list .tb_row > div a,
div.tdfy_data_list .tb_row div label {
  margin: 0px !important;
}

div.tdfy_bg_data_list .tb_row > div a,
div.tdfy_data_list_vd .tb_row div label {
  margin: 0px !important;
}

div.tdfy_bg_data_list > .tdfy_data_list_header > div > div > div {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 0px 10px;
}

div.tdfy_bg_data_list .tb_row > div.tdfy_data_list-n > div.tdfy_data_list-n-nn {
  display: flex;
  flex-direction: column;
}

div.tdfy_bg_data_list
  .tb_row
  > div.tdfy_data_list-n
  > div.tdfy_data_list-n-nn
  > label {
  font-size: 14px;
  margin-bottom: 2px;
}

div.tdfy_bg_data_list .tb_row:nth-child(2n) {
  background: rgba(255, 255, 255, 0.01);
}

div.tdfy_bg_data_list .tb_row:hover {
  background: var(--rx-table-hover);
}

div.tdfy_bg_data_list .tdfy_bg_data_list-body .tb_row {
  border-top: 1px solid var(--rx-table-border-color);
}

div.tdfy_bg_data_list .tb_row {
  width: 100%;
  min-height: 38px;
  display: flex;
  align-items: stretch;
  color: var(--rx-text);
  cursor: pointer;
}

div.tdfy_bg_data_list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.tdfy_bg_data_list-header {
  background-color: var(--rx-table-header) !important;
}

.tdfy_bg_data_list-header .tb_row > div {
  color: var(--rx-table-header-color) !important;
  font-weight: 600;
}

.tdfy_bg_data_list-header .tb_row:hover {
  background: var(--rx-table-header) !important;
}

div.tdfy_bg_data_list .tb_row > div.tdfy_data_list_right {
  display: flex;
  justify-content: flex-end;
}

div.tdfy_bg_data_list .tb_row > div.tdfy_data_list_center {
  display: flex;
  justify-content: center;
}

.white {
  color: var(--rx-bar-text) !important;
}

.DepositDetailsBox {
  padding: 1rem;
  background: var(--rx-bg-secondary);
  margin: 0.4rem 0;
  border: 1px solid var(--rx-border-color);
}

.xsbtn-group .btn {
  margin: 3px 3px;
}

.gtx-btn-group {
  display: flex;
  justify-content: flex-end;
}

.gtx-btn-group .btn {
  margin-right: 5px;
}

.gtx-btn-group .btn:last-child {
  margin-right: 0px;
}

.deptTopBal {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem -0.3rem;
  margin-bottom: 0.4rem;
}

.deptTopBal > div {
  flex-grow: 1;
  padding: 0rem 0.3rem;
}

.deptTopBal > div:last-child {
  margin: 0rem;
}

.deptTopBal > div > div {
  flex-grow: 1;
  background: var(--rx-bg-secondary);
  border: 1px solid var(--rx-border-color);
  padding: 0.5rem;
  text-align: center;
  margin: 0.2rem 0rem;
}

/* ---------------------- big table format ----------------- */

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
  transition: opacity 0s linear;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: var(--rx-border-color);
  border-radius: 0px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 1s linear;
}

div.tdfy_data_list .tb_row > div.tdfy_data_list_left {
  display: flex;
  justify-content: flex-start;
}

div.tdfy_data_list_vd .tb_row > div.tdfy_data_list_left {
  display: flex;
  justify-content: flex-start;
}

.odb-height_Sell {
  height: calc(32.5vh - 76px);
  position: relative;
}

.odb-height_Buy {
  height: calc(32.5vh - 76px);
  position: relative;
}

.orderBookHeight {
  height: calc(32.5vh - 76px);
  position: relative;
}

/* .order-form-inner{
    height: calc(100vh - 126px);
} */

.spread {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--rx-text);
  background: var(--rx-bg-secondary);
  min-height: 28px;
  border: 1px solid var(--rx-border-color);
  padding: 5px 10px 5px 10px;
}

.spread div {
  display: flex;
  flex-grow: 1;
  font-size: 12px;
}

.spread div:last-child {
  justify-content: flex-end;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

._xdCard_header .tb_row {
  position: relative;
  width: 100%;
  height: 22px;
  min-height: 22px;
  display: flex;
  align-items: stretch;
  color: var(--rx-text);
  cursor: pointer;
  z-index: 1;
}

._xdCard_header .tb_row > div.tdfy_data_list_left {
  display: flex;
  justify-content: flex-start;
}

._xdCard_header .tb_row > div.tdfy_data_list_right {
  display: flex;
  justify-content: flex-end;
}

._xdCard_header .tb_row > div {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0px 10px;
}

.mkd-height {
  height: 485px;
}

.mks-height {
  max-height: 266px;
}

.tdfy_xd145 {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

._xinput_pdd {
  margin-bottom: 0.5rem;
}

._xinput_pdd div {
  position: relative;
}

._xinput_pdd .__sje {
  padding: 0.375rem 4.4rem 0.375rem 0.75rem;
  text-align: right !important;
  background-color: var(--rx-bg-secondary) !important;
}

._xinput_pdd .form-control {
  background-color: var(--rx-bg-secondary) !important;
}

._xinput_pdd label {
  font-size: 12px;
  margin-bottom: 0.2rem !important;
  color: var(--rx-text);
}

.keipdl {
  position: absolute;
  top: 14px;
  right: 0;
  font-size: 12px;
  line-height: 10px;
  padding: 0 10px;
  max-width: 4rem;
  border-left: 1px solid var(--rx-border-color);
}

.sm_sprt {
  display: flex;
}

.sm_sprt > div {
  margin-right: 10px;
}

.sm_sprt > div:last-child {
  margin-right: 0px;
}

.fs-12 {
  font-size: 0.75rem;
}

.ShortMarket {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.SHORT_MKT {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 60px;
}

.SHORT_MKT > div > span {
  margin-right: 5px;
  font-weight: 500;
}

.SHORT_MKT .SHORT_MKT_LIST {
  position: absolute;
  padding: 10px;
  top: 59px;
  left: 0;
  background-color: var(--rx-bg-secondary) !important;
  border: 1px solid var(--rx-bg-topbar-border);
  width: 960px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

/* .SHORT_MKT:hover .SHORT_MKT_LIST{
    display: block;
} */

.short_market_tb_header .short_market_tb_header_upper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.short_market_tabs {
  width: 100%;
  margin: 12px 0px;
  order: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ox-height {
  height: calc(100vh - 250px);
}

.dust-height {
  max-height: calc(100vh - 400px);
}

.wallet-panel-height {
  height: calc(100vh - 186px);
}

.estimated {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 10px;
}

.xm-btn {
  font-size: 13px !important;
  padding: 4px 10px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.tdfy-twin {
  display: flex;
  justify-content: space-evenly !important;
}

.pdedx_5 {
  display: flex;
}

.pdedx_5 div:last-child {
  margin-left: 10px;
}

.__SEFDFSFG54 {
  display: flex;
}

.__SEFDFSFG54 .__SEFD_Child {
  flex-grow: 1;
  margin-top: 10px;
  max-width: 50%;
}

.__SEFDFSFG54 .__SEFD_Child:last-child {
  margin-left: 10px;
}

.modal-header {
  border-bottom: 1px solid var(--rx-border-color);
}

.modal-header .modal-title {
  color: var(--rx-text);
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid var(--rx-border-color);
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.depositQRBar {
  padding: 1.5rem;
  background-color: var(--white);
  border-radius: 10px;
  border: 1px solid var(--rx-border-color);
}

.Qr-panel .refCode {
  padding: 10px;
  border: 1px solid var(--rx-border-color);
  margin-top: 1rem;
  word-break: break-all;
  text-align: center;
}

.dx_form label {
  font-size: 13px;
  margin: 0px;
}

.error_border {
  border: 1px solid var(--danger) !important;
}

.error_border2 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid var(--danger) !important;
}

.warning-box {
  background: var(--danger);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  font-size: 12px;
  padding: 3px 5px;
}

.warning_caution {
  font-size: 0.65rem;
  font-weight: 500;
  color: var(--red);
}

.AffiliatesQrBar {
  padding: 15px;
  background: var(--white);
  border-radius: 5px;
  display: inline-block;
  border: 1px solid var(--rx-border-color);
}

.red .fa-user {
  color: var(--rx-text) !important;
}

.red {
  color: var(--red);
}

.accver {
  height: auto;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  border: 1px solid var(--rx-border-color);
  color: var(--rx-text);
  word-break: break-word;
  white-space: normal;
  padding: .375rem .75rem;
  line-height: 1.5;
  border-radius: .25rem;
}

.ava {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: var(--rx-primary-color);
  color: var(--rx-btn-color);
  line-height: 120px;
  text-align: center;
  float: left;
  margin-right: 20px;
}

.Aff_upper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  margin: 0px -10px;
}

.Aff_upper .Aff_upper_child {
  width: 50%;
  padding: 0px 10px;
}

.funds_fullName {
  font-size: 0.72rem;
  color: var(--gray);
  white-space: break-spaces;
}

.USDspan {
  font-size: 0.72rem;
  color: var(--gray);
}

.ValidateError {
  margin-top: 5px;
  color: var(--danger);
  font-size: 0.7rem;
  font-weight: 500;
}

.Details-box {
  padding: 15px;
  border: 1px solid var(--rx-border-color);
  background-color: var(--rx-bg-secondary);
  margin-bottom: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.Form-box {
  padding: 15px;
  border: 1px solid var(--rx-border-color);
  background-color: var(--rx-bg-secondary);
  margin-bottom: 10px;
  border-radius: 5px;
}

.lvl_val {
  padding: 10px;
  border: 1px solid var(--rx-border-color);
  background: var(--rx-bg-secondary);
  margin-bottom: 10px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.subAccount-Menu-box {
  padding: 10px;
  border: 1px solid var(--rx-border-color);
  background: var(--rx-bg-secondary);
  margin-bottom: 10px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    color: var(--rx-primary-color);
    border: 1px solid var(--rx-primary-color);
  }

  &.active {
    color: var(--rx-primary-color);
    border: 1px solid var(--rx-primary-color);
  }
}

@media only screen and (max-width: 768px) {
  .subAccount-Menu-box {
    min-height: auto;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }
}

/* .lvl_val div:first-child {
    margin-bottom: 10px;
    font-size: 16px;
} */

.lvl_val div:last-child {
  font-size: 24px;
}

.lvl_val:last-child {
  margin-bottom: 0px;
}

.footer-section {
  padding: 15px 85px 15px 85px;
  background: var(--rx-bg-secondary) !important;
  border-top: 1px solid var(--rx-border-color);
}

.footer-menu {
  border-bottom: 1px solid var(--rx-border-color);
  margin-bottom: 15px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-content p {
  width: 33.333%;
  text-align: center;
  margin-bottom: 0;
}

.tdfy-addon {
  position: relative;
}

.tdfy-addon input {
  padding-right: 50px;
}

.tdfy-addon .sm-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  cursor: pointer;
}

.sitesettings {
  margin: 0px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  fill: var(--white);
  border: 2px solid var(--rx-bg-topbar-border) !important;
  position: relative;
  /* -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12); */
}

.x-drop {
  background: var(--rx-bg-secondary) !important;
  border: 2px solid var(--rx-bg-topbar-border) !important;
  border-radius: 5px;
  color: var(--rx-text) !important;
}

.sitesettings:hover {
  background-color: var(--rx-bg-secondary) !important;
}

.x-drop svg {
  fill: var(--rx-bar-text) !important;
}

.x-drop::after {
  content: none !important;
}

.gravatar img {
  width: 200px;
}

.inviteLink {
  background: var(--rx-bg-secondary);
  border: 1px solid var(--rx-border-color);
  padding: 20px 15px;
  text-align: center;
  min-height: 410px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

.Addon-input {
  padding-right: 50px !important;
}

.fa-icons {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin: 5px;
}

.fa-icons .fa-facebook-f {
  background: #3b5998;
  color: var(--white);
}

.fa-icons .fa-google-plus-g {
  background: #dd4b39;
  color: var(--white);
}

.fa-icons .fa-twitter {
  background: #3b5998;
  color: var(--white);
}

.fa-icons .fa-whatsapp {
  background: #30b844;
  color: var(--white);
}

.Top_changePriceUp {
  color: var(--success);
}

.Top_changePriceDown {
  color: var(--danger);
}

.fa-icons i {
  font-size: 16px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.sitesettings svg {
  fill: var(--rx-bar-text) !important;
  height: 18px;
  width: 18px;
}

.Fiat_price {
  font-size: 0.65rem;
  color: var(--gray);
}

.Apikeys_setting .form-control {
  display: block;
  width: 100%;
  margin-top: 5px;
}

.Apikeys_setting .btn {
  margin-top: 5px;
}

.Apikeys_setting label {
  display: block;
  width: 100%;
}

.Apikeys_setting .form-group {
  margin-left: 10px;
}

.Apikeys_setting .form-group:first-child {
  margin-left: 0px;
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.modal-backdrop {
  background-color: var(--rx-backdrop) !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--rx-bg-secondary);
  background-clip: padding-box;
  border: 0px;
  border-radius: 0.3rem;
  outline: 0;
}

.close {
  color: var(--rx-text) !important;
  text-shadow: none;
  opacity: 1;
}

@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }

  100%,
  12% {
    transform: rotate(-405deg);
  }
}

.hidden,
.l1K05a {
  display: none;
}

._1Wg07 {
  padding-left: 35px;
}

.flex-log {
  display: flex;
  flex-direction: row;
}

.flex-log .floating-form {
  margin-right: 10px;
}

.flex-log .floating-form:last-child {
  margin-right: 0px;
}

.mx_1d45d {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 0;
}

.low-color {
  background-color: rgba(255, 0, 29, 0.11) !important;
}

.high-color {
  background-color: rgba(40, 167, 69, 0.11) !important;
}

._menu-btn {
  display: none;
}

._menu-btn svg {
  fill: var(--rx-bar-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.authQr {
  width: 210px;
  height: 210px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--rx-border-color);
  padding: 5px;
}

.myref {
  order: 2;
  max-width: 50%;
}

.edr1e4 {
  order: 1;
}

.edr1e5 {
  order: 3;
}

.row {
  margin-right: -5px !important;
  margin-left: -5px !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-is-1,
.col-is-2,
.col-is-3,
.col-is-4,
.col-is-5,
.col-is-6,
.col-is-7,
.col-is-8,
.col-is-9,
.col-is-10,
.col-is-11,
.col-is-12,
.col-is,
.col-is-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto,
.col-xxxl-1,
.col-xxxl-2,
.col-xxxl-3,
.col-xxxl-4,
.col-xxxl-5,
.col-xxxl-6,
.col-xxxl-7,
.col-xxxl-8,
.col-xxxl-9,
.col-xxxl-10,
.col-xxxl-11,
.col-xxxl-12,
.col-xxxl,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.overflow-auto {
  overflow: auto;
}

.xdiv-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
}

.py-15 {
  padding: 0px 15px;
}

.sm-chart {
  flex-grow: 1;
  width: 50%;
  padding-right: 5px;
  height: calc(40vh - 14px);
}

.trade-feed {
  flex-grow: 1;
  width: 50%;
  padding-left: 5px;
  height: calc(40vh - 14px);
}

.lg-chart {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.auth_codescan {
  display: flex;
  justify-content: flex-start;
}

.auth-write {
  width: 72%;
  padding: 0px 15px;
}

.primary-text {
  color: var(--rx-primary-color);
}

.pxlv {
  padding: 1.5rem !important;
}

.signup_form {
  background: var(--rx-bg-secondary) !important;
  width: 767px;
  padding: 2em 3rem;
  font-size: 14px;
  border: 1px solid var(--rx-border-color) !important;
}

.signup_form p {
  color: var(--rx-text) !important;
  font-size: 14px;
}

.signup_form a {
  font-size: 14px;
}

.signup_form .tittle_xl {
  color: var(--rx-text) !important;
  font-size: 34px;
  margin-bottom: 20px;
  position: relative;
}

.signup_form .form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--rx-text);
  background-color: transparent;
  background-clip: padding-box;
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid var(--rx-text) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none !important;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--rx-bg-secondary) !important;
  border: 2px solid var(--rx-primary-color) !important;
  border-radius: 5px;
}

.lablcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.8em;
  color: var(--rx-text) !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-menu {
  color: var(--rx-text) !important;
  background-color: var(--rx-bg-secondary) !important;
  border: 2px solid var(--rx-bg-topbar-border) !important;
}

.login_form {
  width: 450px;
  background: var(--rx-bg-secondary) !important;
  padding: 2em 3rem;
  font-size: 14px;
  border: 1px solid var(--rx-border-color) !important;
}

.login_form p {
  color: var(--rx-text) !important;
  font-size: 14px;
}

.login_form a {
  font-size: 14px;
}

.login_form .tittle_xl {
  color: var(--rx-text) !important;
  font-size: 34px;
  margin-bottom: 20px;
  position: relative;
}

.floating-form {
  width: 100%;
}

/****  floating-Lable style start ****/
.floating-label {
  position: relative;
  margin-bottom: 20px;
}

.floating-input,
.floating-select {
  font-size: 14px;
  padding: 5px 15px;
  display: block;
  width: 100%;
  height: 56px;
  color: var(--rx-text) !important;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid var(--rx-border-color) !important;
}

.floating-input-focused,
.floating-select-focused {
  outline: none;
  border: 2px solid var(--rx-border-color) !important;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
  border: 2px solid var(--rx-border-color) !important;
}

.floating-label label {
  color: var(--rx-text) !important;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 8px;
  top: 18px;
  transition: 0.1s ease all;
  -moz-transition: 0.1s ease all;
  -webkit-transition: 0.1s ease all;
  background: var(--rx-bg-secondary) !important;
  padding: 0px 8px;
}

.floating-input-focused ~ label,
.floating-input-focused:not(:placeholder-shown) ~ label {
  top: -10px;
  font-size: 14px;
  color: var(--rx-text) !important;
}

.floating-input:focus ~ label,
.floating-input:not(:placeholder-shown) ~ label {
  top: -10px;
  font-size: 14px;
  color: var(--rx-text) !important;
}

.floating-select:focus ~ label,
.floating-select:not([value=""]):valid ~ label {
  top: -10px;
  font-size: 14px;
  color: var(--rx-text) !important;
}

.floating-input-focused:focus ~ .bar:before,
.floating-input-focused:focus ~ .bar:after,
.floating-select-focused:focus ~ .bar:before,
.floating-select-focused:focus ~ .bar:after {
  width: 50%;
}

/* active state */
.floating-input:focus ~ .bar:before,
.floating-input:focus ~ .bar:after,
.floating-select:focus ~ .bar:before,
.floating-select:focus ~ .bar:after {
  width: 50%;
}

.floating-textarea {
  min-height: 30px;
  max-height: 260px;
  overflow: hidden;
  overflow-x: hidden;
}

/* highlighter */
.highlight {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 15%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.floating-input:focus ~ .highlight,
.floating-select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.1s ease;
  -moz-animation: inputHighlighter 0.1s ease;
  animation: inputHighlighter 0.1s ease;
}

/* animation */
@-webkit-keyframes inputHighlighter {
  from {
    background: var(--white);
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: var(--white);
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: var(--white);
  }

  to {
    width: 0;
    background: transparent;
  }
}

@media only screen and (max-width: 768px) {
  .login_form {
    padding: 1rem !important;
  }
}

.overflow-container {
  overflow-x: auto;
}

@media only screen and (min-width: 1480px) {
  .all-open-orders-container {
    width: 100% !important;
    height: 100%;
  }
}

.all-open-orders-container {
  min-width: 890px;
  width: 100%;
  height: 100%;
}

.visualCaptcha-possibilities {
  display: inline-block !important;

  margin-top: 10px;
}

.visualCaptcha-button-group {
  display: inline-block !important;

  margin-top: 10px;
  padding: 11px 0px;
}

.accessibility-description,
.status.valid,
.visualCaptcha-explanation {
  border-radius: 5px;
  border: 1px solid var(--white);
  padding: 10px;
  text-align: center;
  font-family: Oxygen, sans-serif;
  font-size: 18px;
  color: var(--white);
  font-weight: 400;
  margin: 0px 0px 5px 0px;
}

.visualCaptcha-possibilities img {
  width: 32px;
  height: 32px;
  z-index: 5;
  transition: all 200ms;
  -webkit-transition: all 200ms;
}

.visualCaptcha-possibilities .img {
  padding: 10px;
  border: 1px solid var(--white);
  border-radius: 5px;
  display: inline-block !important;
}

.visualCaptcha-possibilities {
  margin-right: 15px;
}

.visualCaptcha-refresh-button a {
  color: var(--white) !important;
}

.visualCaptcha-refresh-button {
  padding: 7px 20px 7px;
  margin: 0 5px;
  background-color: var(--danger);
  border: 2px solid var(--danger);
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  color: var(--white);
  cursor: pointer;
  display: inline-block !important;
}

.visualCaptcha-selected {
  padding: 10px !important;
  border: 3px dashed var(--rx-primary-color) !important;
}

.floating-group {
  position: relative;
}

.floating-group .floating-input {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.floating-group .floating-label {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

.floating-group .floating-label .input-group-append .btn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.email-otp-panel {
  width: 100%;
  max-width: 480px;
}

.toast-container .toast {
  box-shadow: 0 0 12px var(--black) !important;
  opacity: 1;
  cursor: pointer;
}

.with-addon .form-control {
  padding-right: 60px;
}

.with-addon .input_addon {
  position: relative;
}

.CurrCurrency_section {
  position: absolute;
  top: 14px;
  right: 0;
  font-size: 0.8rem;
  line-height: 10px;
  padding: 0 12px;
  border-left: 1px solid var(--rx-border-color);
  color: var(--rx-text);
  font-weight: 600;
}

.Enable2fa {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 206px);
}

/* --------------------Loader----------------------- */

.loader {
  border: 5px solid var(--rx-primary-color);
  border-radius: 50%;
  border-top: 5px solid var(--rx-border-color);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}

.loader-sm {
  border: 4px solid var(--rx-primary-color);
  border-radius: 50%;
  border-top: 4px solid var(--rx-border-color);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* --------------------Loader----------------------- */

.sidenav {
  position: fixed;
  display: flex;
  box-sizing: border-box;
  height: calc(100vh - 64px);
  max-height: 100%;
  width: 70px;
  align-items: flex-start;
  z-index: 99;
}

.x_sidebar {
  height: 100%;
  width: 100%;
  border-right: 1px solid var(--rx-border-color);
  background: var(--rx-bg-bar);
}

.x_sidebar .x_sidebar_ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
}

.x_sidebar .x_sidebar_ul li {
  display: flex;
  width: 70px;
  max-height: 75px;
  border-bottom: 1px solid rgba(40, 54, 88, 0.25);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  position: relative;
}

.x_sidebar .x_sidebar_ul li a {
  display: flex;
  width: 70px;
  max-height: 75px;
  border-bottom: 1px solid rgba(40, 54, 88, 0.25);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  position: relative;
}

.x_sidebar .x_sidebar_ul li:last-child {
  border-bottom: 0px;
}

.x_sidebar .x_sidebar_ul li a:last-child {
  border-bottom: 0px;
}

.x_sidebar .x_sidebar_ul > li > .iconbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.x_sidebar .x_sidebar_ul > li > a > .iconbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.x_sidebar .x_sidebar_ul > li > .iconbox > span > svg {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-bottom: 9px;
  fill: var(--rx-bar-text);
}

.x_sidebar .x_sidebar_ul > li > a > .iconbox > span > svg {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  margin-bottom: 9px;
  fill: var(--rx-bar-text);
}

.x_sidebar .x_sidebar_ul > li:hover > .iconbox > span > svg {
  fill: var(--white);
}

.x_sidebar .x_sidebar_ul > li > a:hover > .iconbox > span > svg {
  fill: var(--white);
}

.x_sidebar .x_sidebar_ul > li > .iconbox > span:last-child {
  color: var(--rx-bar-text);
  text-align: center;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 400;
  word-break: break-word;
}

.x_sidebar .x_sidebar_ul > li > a > .iconbox > span:last-child {
  color: var(--rx-bar-text);
  text-align: center;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 400;
  word-break: break-word;
}

.x_sidebar .x_sidebar_ul > li:hover > .iconbox > span:last-child {
  color: var(--white);
}

.x_sidebar .x_sidebar_ul > li > a:hover > .iconbox > span:last-child {
  color: var(--white);
}

.mob-logo-panel {
  display: none;
}

.Mob_wallet_Balance {
  display: block;
  font-size: 0.75rem;
}

.trade-percent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade-percent > button {
  background: var(--rx-bg-secondary);
  color: var(--rx-text);
  padding: 5px 10px;
  border: 1px solid var(--rx-border-color);
  border-radius: 3px;
  flex-grow: 1;
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
}

.trade-percent > button.active {
  background: var(--rx-primary-color);
  color: var(--rx-btn-color);
  border: 1px solid var(--rx-primary-color);
}

.trade-percent > button:last-child {
  margin-right: 0px;
}

.topmovers-converted {
  display: block;
  color: var(--gray);
  font-size: 0.696584rem;
}

.simpleLoaderPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.main-chart-list {
  display: flex;
  align-items: center;
}

.main-chart-pill {
  margin-left: 10px;
  cursor: pointer;
}

.main-chart-tab {
  display: none;
}

.main-chart-active {
  display: block;
}

.main-pill-active {
  color: var(--rx-primary-color);
}

.order-form-height {
  height: calc(100vh - 74px);
}

.tradeChart {
  width: 100%;
  height: calc(69vh - 129px);
}

.depthChart {
  width: 100%;
  display: block;
  height: calc(69vh - 139px);
}

.order-book-height {
  height: calc(69vh - 112px);
}

.trade-fee-height {
  height: calc(30vh - 38px);
}

.open-orders-height {
  height: calc(36vh - 57px);
}

.market-stats {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 80%;

  .market-stats-panel {
    width: 100%;

    .maket-stats-data {
      display: flex;
      min-width: 500px;

      .tdfy_xd142 {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        color: var(--rx-text);
        padding: 0 0.5rem;
        border-right: 1px solid var(--rx-border-color);
      }

      .tdfy_xd142:last-child {
        border-right: 0px;
      }
    }
  }

  .arrow-btn-icon {
    padding: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--rx-bg-default);
  }
}

.owl-dt-control-arrow-button svg {
  fill: var(--rx-primary-color);
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
}

.owl-dt-control-button {
  color: var(--rx-primary-color);
}

.switch-input {
  display: none;
}

.switch-label {
  position: relative;
  display: inline-block;
  min-width: 112px;
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  margin: 16px;
  padding: 16px 0 16px 44px;
}

.switch-label:before,
.switch-label:after {
  content: "";
  position: absolute;
  margin: 0;
  outline: 0;
  top: 50%;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.switch-label:before {
  left: 1px;
  width: 34px;
  height: 14px;
  background-color: var(--secondary);
  border-radius: 8px;
}

.switch-label:after {
  left: 0;
  width: 20px;
  height: 20px;
  background-color: var(--rx-primary-color);
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),
    0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
}

.switch-label .toggle--on {
  display: none;
}

.switch-label .toggle--off {
  display: inline-block;
}

.switch-input:checked + .switch-label:before {
  background-color: var(--rx-primary-color-light);
}

.switch-input:checked + .switch-label:after {
  background-color: var(--rx-primary-color);
  -ms-transform: translate(80%, -50%);
  -webkit-transform: translate(80%, -50%);
  transform: translate(80%, -50%);
}

.switch-input:checked + .switch-label .toggle--on {
  display: inline-block;
}

.switch-input:checked + .switch-label .toggle--off {
  display: none;
}

.bal-box {
  display: flex;
  align-items: baseline;

  .bal-box-inner {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
}

.bal-filter-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bx-mob-table {
  .mob-row {
    display: flex;
    flex-direction: row;
    min-width: 0px;

    .mob-col {
      padding: 0rem 0.2rem;
      min-width: 0px;
    }
  }
}

.sk-box {
  border: 1px solid var(--rx-border-color);
  background: var(--rx-bg-secondary);
}

.mob-icon-btn {
  padding: 0.5rem;
}

@media only screen and (max-width: 1260px) {
  .order-form-height {
    height: calc(118vh - 8px);
  }

  .tradeChart {
    width: 100%;
    height: 50vh;
  }

  .depthChart {
    width: 100%;
    display: block;
    height: 50vh;
  }

  .order-book-height {
    height: calc(69vh - 108px);
  }

  .trade-fee-height {
    height: calc(69vh - 118px);
  }

  .open-orders-height {
    height: 35vh;
  }

  .market-stats .market-stats-panel .maket-stats-data {
    display: flex;
    min-width: 700px;
  }
}

@media only screen and (max-width: 1080px) {
  .SHORT_MKT .SHORT_MKT_LIST {
    position: fixed;
    padding: 10px;
    top: 59px;
    left: 0;
    background-color: var(--rx-bg-secondary) !important;
    border: 1px solid var(--rx-bg-topbar-border);
    width: 100%;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }

  .features {
    .featuresDrop {
      position: fixed;
      width: 100vw;
    }
  }

  .top-options {
    .setting_web {
      display: none;
    }

    // .side-menu-bar {
    //   display: flex !important;
    //   align-items: center;
    // }
    .settings_mob {
      display: flex !important;
      align-items: center;
    }
  }

  .bal-box {
    flex-direction: column;

    .bal-box-inner {
      font-size: 0.85rem;
    }
  }

  .market-stats .market-stats-panel .maket-stats-data {
    display: flex;
    min-width: 700px;
  }
}

@media only screen and (max-width: 992px) {
  .coinIcon {
    width: 20px !important;
    border-radius: 10px;
  }

  .order-book-height {
    height: 56vh;
  }

  .trade-fee-height {
    height: 50vh;
  }

  .order-form-height {
    height: 100%;
  }

  .right-nav-menu {
    display: none !important;
  }

  .top-options {
    .side-menu-bar {
      display: flex !important;
      align-items: center;
    }
  }

  .features {
    display: none;
  }

  .side-nav-panel {
    display: block;
  }

  .market-stats {
    width: 60%;
  }

  .market-stats-data {
    min-width: 600px;
  }

  .x_sidebar .x_sidebar_ul {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  .SHORT_MKT:hover {
    background: transparent;
  }

  .SHORT_MKT .SHORT_MKT_LIST {
    position: fixed;
    padding: 10px;
    top: 59px;
    left: 0;
    background: var(--rx-bg-secondary);
    border: 1px solid var(--rx-border-color);
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .market-top-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid var(--rx-border-color);
    padding: 10px;

    .market-dropdown {
      .market-drop-btn {
        position: relative;
        font-size: 15px;
      }

      .market-drop-list {
        position: absolute;
        padding: 10px;
        top: 50px;
        left: 0;
        background: var(--rx-bg-secondary);
        border: 1px solid var(--rx-border-color);
        width: 100%;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
        z-index: 99;
      }
    }
  }

  .ShortMarket {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    padding: 0px 15px;
    color: var(--rx-text);
    background: var(--rx-bg-secondary);
    border: 2px solid var(--rx-border-color);
    border-radius: 5px;
  }

  .short_market_tabs {
    flex-wrap: wrap;
  }

  .header-mkt {
    flex-grow: 1;
  }

  .marketmore_options {
    flex-grow: 1;
  }

  .rx-navbar > div > div div.brand-logo {
    display: flex;
    width: auto;
    min-width: auto;
    align-items: center;
    cursor: pointer;
    margin-left: 0px !important;
  }

  .mob-hide {
    display: none;
  }

  .content-view {
    padding: 1rem;
  }

  // .setting_web {
  //   display: none;
  // }

  .x_sidebar {
    width: 100%;
    background: var(--rx-bg-bar);
  }

  .mob-logo-panel {
    padding: 15px;
    height: 60px;
    display: block;
    border-bottom: 1px solid var(--rx-primary-color);
  }

  .mob-logo-panel img {
    width: auto;
    height: 100%;
  }

  .sidenav {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 120;
    height: 100vh;
    width: 100%;
    /* padding-top: 60px; */
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.4);
  }

  .cancel-btn {
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
  }

  .cancel-btn svg {
    width: 18px !important;
    height: 18px !important;
    fill: var(--rx-primary-color) !important;
  }

  .x_sidebar .x_sidebar_ul li {
    display: flex;
    width: 100%;
    max-height: 100%;
    border-bottom: 1px solid var(--rx-border-color);
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    cursor: pointer;
    flex: inherit;
    position: relative;
    padding: 15px;
  }

  .x_sidebar .x_sidebar_ul > li > span > svg {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    margin-bottom: 0px;
    fill: var(--rx-bar-text);
  }

  .x_sidebar .x_sidebar_ul > li.is-active > span:last-child {
    color: var(--white);
  }

  .x_sidebar .x_sidebar_ul > li.is-active > span > svg {
    fill: var(--white);
  }

  .x_sidebar .x_sidebar_ul > li > a.is-active > span > svg {
    fill: var(--white);
  }

  .x_sidebar .x_sidebar_ul > li > span:last-child:hover {
    color: var(--white);
  }

  .x_sidebar .x_sidebar_ul > li > span > svg:hover {
    fill: var(--white);
  }

  .x_sidebar .x_sidebar_ul > li > a > span > svg:hover {
    fill: var(--white);
  }

  .x_sidebar .x_sidebar_ul > li > a > span > svg {
    width: 25px;
    min-width: 25px;
    height: 25px;
    min-height: 25px;
    margin-bottom: 0px;
    fill: var(--white);
  }

  .x_sidebar .x_sidebar_ul > li > span:last-child {
    color: var(--rx-text);
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 400;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .rx-navbar > div > div div.brand-logo img {
    max-width: 140px;
    max-height: 70px;
  }

  .SHORT_MKT {
    border-left: 0px;
    height: 60px;
  }

  .menu-icon-bar {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 18px;
    color: var(--rx-bar-text);
  }

  .x_sidebar .x_sidebar_ul > .is-active > .menu-icon-bar {
    color: var(--white) !important;
  }

  .rx-navbar {
    height: 60px !important;
  }

  .x_wrapper {
    width: 100%;
    padding: 5px;
  }

  ._menu-btn {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .content-wrapper {
    padding-top: 60px;
  }

  .rx-navbar > div > div div.top-options {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .tdfy-marketlist {
    min-width: 1000px;
  }

  .resp {
    width: 100%;
    overflow: auto;
  }

  div.tdfy_bg_data_list {
    min-width: 1000px;
  }

  div.tdfy_data_list {
    min-width: 1000px;
  }

  div.tdfy_data_list_vd {
    width: auto;
  }

  div.ressc {
    min-width: 1000px;
  }

  .rp {
    overflow: auto;
  }

  .market_wrapper {
    padding-left: 5px;
  }

  ._2gNK-pDK {
    max-width: 100%;
    width: 100%;
  }

  .prx_1e45 {
    flex-wrap: wrap;
  }

  ._prxd14 {
    flex: 1 0 auto;
    max-width: -webkit-fill-available;
  }

  .buy_sell {
    position: relative;
  }

  .bal-box {
    margin-bottom: 0.5rem;
  }

  .bal-filter-box {
    justify-content: space-between;
  }

  .market-stats .market-stats-panel .maket-stats-data {
    display: flex;
    min-width: 700px;
  }
}

@media only screen and (max-width: 768px) {
  .content-view {
    padding: 1rem;
  }

  .signup_form {
    padding: 1rem !important;
  }

  .flex-log {
    display: flex;
    flex-direction: column;
  }

  .flex-log .floating-form {
    margin-right: 0px;
    width: 100% !important;
  }

  .xr_items {
    width: 100% !important;
  }

  .xr_items .flex-log {
    display: flex;
    flex-direction: row;
  }

  .xr_items .flex-log .floating-form {
    flex-grow: 1;
  }

  .market_tb_header {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  .market_tb_header h4 {
    margin-top: 0.35em;
    margin-bottom: 0.35em;
  }

  .market-tabs {
    flex-basis: 100%;
    order: 2;
    width: 100%;
    text-align: center;
    overflow: auto;
  }

  .market_tabs_btn {
    flex-grow: 1;
    flex-basis: 0;
  }

  .marketmore_options {
    order: 3;
    width: 100%;
  }

  .xdiv-header {
    display: block;
    align-items: center;
    justify-content: flex-start;
    max-height: 100%;
    flex-direction: column;
  }

  .switch-label {
    margin: 0 !important;
  }

  .Aff_upper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .myref {
    order: 1;
    max-width: 100%;
  }

  .myref .inviteLink {
    margin: 0px 0px 10px 0px !important;
  }

  .edr1e5 {
    order: 3;
  }

  .edr1e4 {
    order: 2;
  }

  .lvl_val:last-child {
    margin-bottom: 10px;
  }

  .charts-tile {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }

  .sm-chart {
    flex-grow: 1;
    width: 100%;
    padding-right: 0px;
    height: calc(40vh - 14px);
    display: none;
  }

  .trade-feed {
    flex-grow: 1;
    width: 100%;
    padding-left: 0px;
    height: calc(40vh - 14px);
  }

  .auth-write {
    width: 100%;
    margin-top: 20px;
    padding: 0px 0px;
  }

  .Apikeys_setting {
    display: block;
  }

  .Apikeys_setting .form-group {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .Apikeys_setting > .form-group:last-child label {
    display: none;
  }

  .pxlv {
    padding: 1rem !important;
  }

  .bal-box {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .bal-box-inner {
      display: flex;
      align-items: center;
      font-size: 0.85rem;
    }
  }

  .bal-filter-box {
    justify-content: space-between;
  }

  .market-panel {
    padding: 10px;
  }

  .market-stats .market-stats-panel .maket-stats-data {
    display: flex;
    min-width: 700px;
  }
}

@media only screen and (max-width: 576px) {
  .content-view {
    padding: 1rem;
  }

  ._menu-btn svg {
    width: 26px;
    height: 26px;
  }

  .signup_form {
    padding: 1rem !important;
  }

  .flex-log {
    display: flex;
    flex-direction: column;
  }

  .flex-log .floating-form {
    margin-right: 0px;
    width: 100% !important;
  }

  .xr_items {
    width: 100%;
  }

  .Aff_upper .Aff_upper_child {
    flex-basis: 100%;
    width: 100%;
  }

  .myref {
    order: 1;
    max-width: 100%;
    margin: 0px;
  }

  .edr1e4 {
    order: 2;
  }

  .edr1e5 {
    order: 3;
    margin-top: 10px;
  }

  .myref .inviteLink {
    margin: 10px 0;
  }

  ._fdch {
    flex-basis: 100%;
  }

  ._fdch:last-child {
    flex-grow: 1;
    margin-top: 0px !important;
    margin-left: 0px !important;
  }

  .lvl_val {
    min-height: 110px;
  }

  .py-15 {
    padding: 0;
  }

  .auth_codescan {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .pgr {
    padding-left: 180px;
  }

  .smaaa {
    font-size: 0.9rem;
  }

  .rpns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px !important;
    flex-direction: column;
  }

  .tbs {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
    text-align: center;
  }

  .box-bchl {
    padding-left: 0px !important;
    text-align: center;
  }

  .box-bchl1 {
    width: 120px !important;
    height: 120px !important;
    fill: var(--white) !important;
    background: transparent !important;
    position: unset !important;
    left: 0;
    top: 0;
    padding: 15px;
    border-radius: 0px !important;
    margin: 0px auto !important;
  }

  .bal-box {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .bal-box-inner {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 0.85rem;
    }
  }

  .bal-filter-box {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .filter-box-item {
      flex-grow: 1;
      flex-basis: 0;
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}

@media only screen and (max-width: 420px) {
  .rx-side-navbar {
    position: fixed;
    right: -100%;
    height: 100vh;
    background-color: var(--rx-bg-secondary);
    width: 100%;
    z-index: 1040;
    transition: all 0.5s ease 0s;
  }

  .market-stats {
    width: 100%;
  }

  .open-side-navbar {
    right: 0px;
  }

  .rx-navbar .rx-navbar-start .brand-panel .brand-logo img {
    max-width: 200px;
    max-height: 32px;
  }

  .rx-navbar .rx-navbar-start .brand-panel .brand-logo {
    min-width: 100px;
  }
}

@media only screen and (max-width: 320px) {
  .ShortMarket {
    height: 40px;
    padding: 0px 5px;
  }

  .SHORT_MKT > div > span {
    margin-right: 5px;
    font-weight: 500;
    font-size: 0.8rem;
  }

  ._menu-btn svg {
    width: 26px;
    height: 26px;
  }
}

.footer-content p a {
  color: var(--rx-text) !important;
}

.xsbtn-group .btn {
  margin: 3px 3px;
}

.signupstatuspanel > div {
  padding: 40px;
  background: var(--rx-bg-secondary);
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.tbmkl {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--rx-primary-color);
}

.lime {
  color: var(--rx-primary-color) !important;
}

// input {
//   color: var(--white) !important;
// }

a:not([href]):hover {
  color: var(--rx-primary-color);
  text-decoration: none;
}

#tv_chart_container {
  iframe {
    width: 100%;
    height: calc(100vh - 255px);
    border: 0px !important;
  }
}

iframe {
  width: 100%;
  height: calc(100vh - 255px);
  border: 2px solid var(--rx-border-color);
}

// header bar loader page route

.loading-overlay {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

.barindex {
  z-index: 999;
  position: relative;
}

.linear-activity {
  overflow: hidden;
  width: 100%;
  height: 3px;
  background-color: var(--headbarbg-color);
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: var(--rx-primary-color-dark);
  animation: indeterminate_first 3s infinite ease-out;
}

.indeterminate:after {
  content: "";
  position: absolute;
  height: 100%;
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
  animation: indeterminate_second 3s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
    left: -100%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
    left: -150%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

//p2p

.checkedform {
  display: flex;
  align-items: center;
  padding: 15px;
  background: var(--rx-card-bg);
}

.convert-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
}

@media screen and (max-width: 767px) {
  .checkedform {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: var(--rx-card-bg);
  }

  .offers {
    padding: 0 !important;
  }

  .offers .form-group .label {
    display: none !important;
  }

  .pdedx_5 {
    display: inline-box !important;
  }

  .pdedx_5 div {
    margin: 0 !important;
  }

  .showinterval {
    display: none;
  }

  .showbarinterval {
    display: flex;
  }

  .barpd {
    padding: 0px;
  }

  .convert-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5rem;

    h4 {
      font-size: 16px;
      line-height: 20px;
    }

    .btn {
      font-size: 14px;
      line-height: 14px;
      padding: 10px 15px;
    }
  }
}

@media screen and (min-width: 767px) {
  .showinterval {
    display: block;
  }

  .showbarinterval {
    display: none;
  }

  .barpd {
    padding: 10px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--danger);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--danger);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.input-group-text {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
  border-color: var(--rx-primary-color);
}

.switchbox {
  border: 1px solid;
  padding: 10px;
  margin-right: 15px;
  border-radius: 15px;
}

.editoffer-top {
  padding: 1rem;
  background-color: var(--rx-bg-default);
}

.crdbg {
  border: 1px solid var(--rx-border-color);
  background-color: var(--rx-bg-secondary);
  margin-bottom: 10px;
}

.dropdown-item {
  color: var(--rx-text);
}

.fx-border {
  border: 1px solid var(--rx-border-color);
}

.fx-bgColor {
  background: var(--rx-bg-secondary);
}

.fx-bgColor2 {
  background: var(--rx-table-header);
}

.fx-border-radius {
  border-radius: 0.25rem;
}

.themeBtns {
  padding: 5px;
  border-radius: 0.25rem;
}

.Daybtn {
  background-color: var(--rx-primary-color);
  color: var(--rx-btn-color) !important;
}

.icon-xxlarge {
  font-size: 4rem;
}

.icon-xlarge {
  font-size: 3rem;
}

.icon-xmedium {
  font-size: 1.5rem;
}

.icon-medium {
  font-size: 1rem;
}

.p2pNameIcon {
  width: 25px;
  height: 25px;
  color: var(--rx-btn-color);
  background: var(--rx-primary-color);
  padding: 5px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  line-height: 15px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px;
  font-size: 11px;
  background-color: var(--rx-table-header);
  border: 1px solid var(--rx-border-color);
}

.minWidth-200 {
  min-width: 200px;
}

.font-xss {
  font-size: 0.65rem;
}

.font-xs {
  font-size: 0.75rem;
}

.font-sm {
  font-size: 0.8rem;
}

.font-md {
  font-size: 1rem;
}

.font-lg {
  font-size: 1.5rem;
}

.font-xl {
  font-size: 2rem;
}

.font-xxl {
  font-size: 4rem;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.coinIcon {
  width: 1.2rem !important;
  border-radius: 10px;
}

.coinIconBig {
  width: 32px !important;
  border-radius: 32px;
}

.tx-badge {
  padding: 2px 4px;
  border-radius: 10%;
}

.feedback-panel {
  margin: 0px;
  min-width: 0px;
  display: flex;
  margin-top: 0.5rem;

  .inner-panel {
    margin: 0px;
    min-width: 0px;
    display: flex;
    width: 100%;
    flex-direction: row;

    .user-details-side {
      width: 25%;
      margin: 0px;
      min-width: 0px;
      display: flex;
      width: 100%;
      flex-direction: column;

      .left-35 {
        padding-left: 35px;
      }
    }

    .feedback-side {
      width: 75%;
      min-width: 0px;
      display: flex;
      width: 100%;

      .feedback-icon {
        width: 25px;
        height: auto;
        margin-right: 10px;
        font-size: 20px;
      }
    }
  }
}

.no-comments {
  height: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.fx-height {
  min-height: 15rem;
  max-height: calc(100vh - 250px);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-grab {
  cursor: grab;
}

.icon-box svg {
  width: 100%;
  height: 80px;
  margin-bottom: 0.5rem;
}

.icon-box svg g {
  fill: var(--rx-primary-color);
}

.default-color {
  color: var(--rx-text) !important;
}

.merchantCheckSVG svg {
  width: 13px;
  height: 13px;
}

.merchantCheckSVG svg g {
  fill: var(--blue);
}

.merchantCheckSVGNot svg {
  width: 13px;
  height: 13px;
}

.merchantCheckSVGNot svg g {
  fill: var(--yellow);
}

.svg-color path {
  fill: var(--rx-primary-color);
}

@media screen and (max-width: 767px) {
  .feedback-panel .inner-panel {
    flex-direction: column;
  }
}

.activeevp {
  color: var(--rx-btn-color);
  background: var(--rx-primary-color) !important;
}

.snapshot {
  img {
    width: 300px;
    height: auto;
  }
}

.tooltips {
  position: absolute;
  left: 58%;
  top: 52%;
  transition: opacity 120ms ease-in-out 0s, transform 120ms ease-in-out 0s;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  background: var(--rx-border-color);
  padding: 15px;
  width: 400px;
}

.tooltips::after {
  content: "";
  position: absolute;
  top: 10%;
  right: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--rx-border-color) transparent transparent;
}

@media only screen and (max-width: 768px) {
  .tooltips {
    position: absolute;
    left: 4%;
    top: 60%;
    transition: opacity 120ms ease-in-out 0s, transform 120ms ease-in-out 0s;
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
    background: var(--rx-border-color);
    padding: 15px;
    width: 350px;
  }

  .tooltips::after {
    content: "";
    position: absolute;
    top: -5%;
    right: 18%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--rx-border-color) transparent;
  }
}

.XWHA {
  border-radius: 5px;
  color: var(--rx-btn-color);
  background: var(--warning);
  border: var(--warning);
}

.dropdown-item:active {
  color: var(--rx-btn-color);
  text-decoration: none;
  background-color: var(--rx-primary-color);
}

.errorpop {
  text-align: center;
  fill: var(--danger);
}

.successpop {
  text-align: center;
  fill: var(--rx-primary-color);
}

.svgg a svg {
  width: 50px;
  height: 50px;
  fill: var(--rx-primary-color) !important;
}

.svgg a:hover svg {
  width: 50px;
  height: 50px;
  fill: var(--rx-primary-color-dark) !important;
}

.addBankLink {
  float: right;
  color: var(--rx-primary-color);
  cursor: pointer;
}

.order-pills .buy-link {
  position: relative;
  color: var(--rx-text);
  padding: 0.25rem;
  text-align: center;
  font-size: 1rem;
  // border: 1px solid var(--success);
  border-radius: 0px;
  cursor: pointer;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

// .order-pills .buy-link.active::after {
//   content: "";
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   border: 16px solid #ff0000;
//   border-left-color: transparent;
//   border-bottom-color: #181f31;
//   border-top-color: #181f31;
//   border-right-color: #181f31;
// }

.order-pills .buy-link.active,
.order-pills .show > .buy-link {
  color: var(--white);
  background-color: var(--success);
}

.order-pills .buy-link:hover {
  color: var(--white);
  background-color: var(--success);
}

.order-pills .sell-link {
  position: relative;
  color: var(--rx-text);
  padding: 0.25rem;
  text-align: center;
  font-size: 1rem;
  // border: 1px solid var(--danger);
  border-radius: 0px;
  cursor: pointer;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

// .order-pills .sell-link.active::after {
//   content: "";
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   border: 16px solid #ff0000;
//   border-left-color: #181f31;
//   border-bottom-color: #181f31;
//   border-top-color: #181f31;
//   border-right-color: transparent;
// }

.order-pills .sell-link.active,
.order-pills .show > .sell-link {
  color: var(--white);
  background-color: var(--danger);
}

.order-pills .sell-link:hover {
  color: var(--white);
  background-color: var(--danger);
}

.text-primary {
  color: var(--rx-primary-color) !important;
}

.bg-primary {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color) !important;
}

.border-primary {
  border-color: var(--rx-primary-color) !important;
}

.btn-primary {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
  border-color: var(--rx-primary-color);
}

.btn-primary:hover {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color-dark);
  border-color: var(--rx-primary-color-dark);
}

.btn-primary.focus,
.btn-primary:focus {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color-dark);
  border-color: var(--rx-primary-color-dark);
  box-shadow: 0 0 0 0.2rem var(--rx-primary-color-light);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
  border-color: var(--rx-primary-color);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color-dark);
  border-color: var(--rx-primary-color-dark);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--rx-primary-color-light);
}

.btn-outline-primary {
  color: var(--rx-primary-color);
  border-color: var(--rx-primary-color);
}

.btn-outline-primary:hover {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color-dark);
  border-color: var(--rx-primary-color-dark);
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem var(--rx-primary-color-light);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--rx-primary-color);
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color-dark);
  border-color: var(--rx-primary-color-dark);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--rx-primary-color-light);
}

.badge-primary {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
}

.badge-primary:hover {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
}

.dropdown-list li h5 {
  margin: 0px;
  font-size: 0.9rem;
  color: var(--gray);
}

@media screen and (min-width: 767px) {
  .rx-navbar > div > div div.brand-logo {
    margin-left: 0px !important;
  }

  .setting_web {
    margin-right: 0px;
  }
}

.hr {
  border: 1px solid var(--rx-border-color);
}

.list-group-styled {
  list-style: none;

  &.listfixedHeight {
    min-height: 200px;
  }

  .li {
    margin-bottom: 1rem;
    font-size: 14px;
  }

  li {
    margin-bottom: 1rem;
    font-size: 14px;
  }
}

.alert-dismissible .close {
  color: inherit !important;
}

.alert-dismissible a {
  text-decoration: underline !important;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0px 0 6px;
  padding: 15px 15px 15px 50px;
  width: 320px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 23px;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 50%) !important;
  color: #ffffff;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: 0em;
  float: right;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.3;
}

.toast-success {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iNjQuMDAwMDAwcHQiIGhlaWdodD0iNjQuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCA2NC4wMDAwMDAgNjQuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+Cgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCw2NC4wMDAwMDApIHNjYWxlKDAuMTAwMDAwLC0wLjEwMDAwMCkiCmZpbGw9IiNmZmZmZmYiIHN0cm9rZT0ibm9uZSI+CjxwYXRoIGQ9Ik0yMjMgNjIyIGMtMTA5IC0zOSAtMTc4IC0xMTIgLTIxMCAtMjIxIC0yOSAtMTAyIDQgLTIyOCA4MiAtMzA2IDEyMgotMTIxIDMyOCAtMTIxIDQ1MCAwIDkxIDkyIDExOCAyNDEgNjQgMzU2IC02OSAxNDYgLTI0MSAyMjMgLTM4NiAxNzF6IG0yODEKLTE4OCBjOSAtOCAxNiAtMjAgMTYgLTI2IDAgLTE4IC0yMzIgLTI0OCAtMjUwIC0yNDggLTE2IDAgLTE1MCAxMzAgLTE1MCAxNDUKMCAzIDUgMTUgMTAgMjYgMTcgMzAgNTEgMjIgOTcgLTIzIGw0MyAtNDMgOTMgOTMgYzUwIDUwIDk5IDkyIDEwOSA5MiA5IDAgMjQKLTcgMzIgLTE2eiIvPgo8L2c+Cjwvc3ZnPgo=");
}

.fs-default {
  font-size: 0.85rem !important;
}

.sk-modal-view {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: auto;
  animation: mymove 0.2s;
}

@keyframes mymove {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

.sk-modal-back {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0.75;
  background-color: rgb(19 22 32 / 65%);
}

.sk-modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.sk-modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.sk-modal-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--rx-bg-secondary);
  background-clip: padding-box;
  border-radius: 0.3rem;
  color: var(--rx-text);
  padding: 1rem;
  font-size: 0.85rem;
  outline: 0;
  box-shadow: 0px 2px 10px 5px rgb(19 23 34 / 50%);
}

@media (min-width: 576px) {
  .sk-modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .sk-modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .sk-modal-lg {
    max-width: 800px !important;
  }

  .sk-modal-xl {
    max-width: 1024px !important;
  }
}

.alert-primary {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
  border-color: var(--rx-primary-color);
}

.bgclr {
  background-color: var(--rx-primary-color);
  color: var(--rx-btn-color) !important;
  border-radius: 10px;

  &:hover {
    background-color: var(--rx-primary-color);
    color: var(--rx-btn-color) !important;
  }
}

.errorBorder {
  border: 1px solid var(--danger) !important;
}

::-webkit-calendar-picker-indicator {
  filter: var(--invert);
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.sub-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  .sub-action-item {
    margin-right: 0.5rem;
    width: 32px;
    height: 32px;

    button {
      width: 100%;
    }

    &:last-child {
      margin-right: 0rem;
    }
  }
}

.mobile-code {
  position: absolute;
  top: 18px;
  left: 15px;
  border-right: 1px solid var(--rx-border-color);
  width: 50px;
}

.rx-box {
  &.border-1 {
    border: 1px solid var(--rx-primary-color);
  }

  &.border-2 {
    border: 2px solid var(--rx-primary-color);
  }

  &.border-default {
    border: 1px solid var(--rx-border-color);
  }

  &.border-radius {
    border-radius: 0.25rem;
  }
}

.fs-1 {
  font-size: 2.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 0.875rem !important;
}

.fs-8 {
  font-size: 0.765rem !important;
}

.fs-9 {
  font-size: 0.675rem !important;
}

.lh-5 {
  line-height: 1.5rem !important;
}

.lh-6 {
  line-height: 1.2rem !important;
}

.px-10x {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-10x {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-5x {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pt-0px {
  padding-top: 0px !important;
}

.pb-0px {
  padding-bottom: 0px !important;
}

.ng-select .ng-select-container {
  color: var(--rx-text);
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--rx-border-color);
  min-height: 38px;
  align-items: center;
}

.ng-select.ng-select-opened > .ng-select-container {
  background: transparent;
  border-color: var(--rx-border-color) var(--rx-border-color)
    var(--rx-border-color);
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: var(--rx-text);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  color: var(--rx-text);
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: 10px;
  padding-bottom: 3px;
}

.position-panel {
  border-bottom: 1px solid var(--rx-border-color);
}

.leverage-indicator {
  position: relative;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  background-image: linear-gradient(to right, var(--green), var(--red));
  overflow: hidden;
  padding: 3px;

  .indicator-inner {
    position: relative;
    margin-right: 3px;
    height: 100%;
  }

  .indicator-label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    opacity: 0.6;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--white);
    }
  }

  .indicator-line {
    position: relative;
    left: 0%;
    height: 100%;
    width: 3px;
    background-color: var(--white);
    border-radius: 5px;
    transition: left 0.8s cubic-bezier(0.69, 2, 0.7, 0.78);

    .indicator-line-label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      left: 5px;
    }

    .indicator-on-left {
      justify-content: flex-end;
      left: -5px;
    }
  }

  .icon-shield {
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
  }

  .icon-warning {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
  }
}

.rx-slider-container {
  display: flex;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: calc(100% - 47px);
  margin-left: 7px;
  height: 25px;
  margin-bottom: 25px;

  .rx-available-bar {
    box-sizing: content-box;
    position: absolute;
    height: 4px;
    background-color: var(--rx-border-color);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-clip: padding-box;
    width: 100%;

    .rx-progress-bar {
      background-color: var(--rx-border-color);
      width: 100%;
      height: 100%;
    }
  }

  .rx-disabled-bar {
    box-sizing: content-box;
    position: absolute;
    height: 4px;
    background-color: rgb(71, 77, 87);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    background-clip: padding-box;
    width: 0%;
  }

  .rx-slider-btn {
    position: absolute;
    left: -4px;
    width: 16px;
    height: 16px;
    color: var(--rx-btn-color);
    background-color: var(--rx-primary-color);
    border-radius: 4px;
    border: 4px solid rgb(245, 245, 245);
    z-index: 20;
    cursor: grab;
    // transition: box-shadow 0.2s ease 0s;
    transition: all 0.5s ease 0s;
    touch-action: none;
    user-select: none;
  }

  .rx-slider-stepper {
    box-sizing: content-box;
    position: absolute;
    background-color: rgb(24, 26, 32);
    color: var(--rx-btn-color);
    width: 6px;
    height: 6px;
    border-radius: 2px;
    border: 2px solid rgb(71, 77, 87);
    z-index: 10;
    overflow: visible;
    cursor: pointer;
    touch-action: none;
    user-select: none;

    .stepper-label {
      position: absolute;
      top: 15px;
      left: -15px;
      width: 40px;
      text-align: center;
      font-size: 10px;
      touch-action: none;
      user-select: none;
    }
  }
}

// ngx-slider cutomization

.ngx-slider {
  margin: 15px 0 30px !important;

  .ngx-slider-pointer {
    top: -6px !important;
    width: 16px !important;
    height: 16px !important;
    color: var(--rx-btn-color);
    background-color: var(--rx-primary-color) !important;
    border-radius: 4px !important;
    border: 4px solid var(--white) !important;
  }

  .ngx-slider-pointer:after {
    display: none;
  }

  .ngx-slider-pointer.ngx-slider-active:after {
    color: var(--rx-btn-color);
    background-color: var(--rx-primary-color) !important;
  }

  .ngx-slider-ticks {
    left: -8px !important;
  }

  .ngx-slider-tick {
    text-align: center;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background: var(--rx-card-bg) !important;
    border: 2px solid var(--gray) !important;
    border-radius: 2px !important;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 11px;
  }

  .ngx-slider-bar {
    left: 0;
    width: 100%;
    height: 4px;
    z-index: 1;
    background: var(--rx-border-color) !important;
    border-radius: 0px !important;
  }

  .ngx-slider-tick-value {
    display: none !important;
  }

  .ngx-slider-tick-legend {
    top: 15px !important;
    font-size: 10px !important;
    color: var(--gray) !important;
  }
}

.custom-form-tab {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--rx-border-color);

  .tab-pill {
    padding: 0px 15px 5px 0px;
    cursor: pointer;

    &:hover {
      color: var(--rx-primary-color);
    }

    &.active {
      color: var(--rx-primary-color);
    }
  }

  .dropdown-item {
    font-size: 0.8rem;
    padding: 0.25rem 0.6rem;
  }

  .dropdown-menu {
    padding: 0px;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: var(--rx-btn-color);
    background-color: var(--rx-primary-color);
  }
}

.form-field-panel {
  border-bottom: 1px solid var(--rx-border-color);

  .disabled {
    opacity: 0.8;
    cursor: default;
  }
}

.rx-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .rx-form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    background: transparent;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
  }

  .rx-input-group-prepend {
    margin-right: -1px;

    .rx-input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0px;
    }
  }

  .rx-input-group-append {
    margin-left: -1px;

    .rx-input-group-text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0px;
    }
  }

  .rx-input-group-append,
  .rx-input-group-prepend {
    display: flex;
  }

  .rx-input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--rx-text);
    text-align: center;
    white-space: nowrap;
    border-top: 1px solid var(--rx-border-color);
    border-bottom: 1px solid var(--rx-border-color);
    border-left: 1px solid var(--rx-border-color);
    border-right: 1px solid var(--rx-border-color);
    border-radius: 0.25rem;
  }
}

.rx-form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--rx-text) !important;
  background-color: transparent;
  background-clip: padding-box;
  border-top: 1px solid var(--rx-border-color);
  border-bottom: 1px solid var(--rx-border-color);
  border-left: 1px solid var(--rx-border-color);
  border-right: 1px solid var(--rx-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.rx-form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}

.badge-panel {
  display: flex;
  justify-content: center;
  align-items: center;

  .badge-panel-pill {
    padding: 5px;
    border: 1px solid var(--rx-border-color);
    border-radius: 0.25rem;
    margin-right: 5px;
    flex: 1;
    text-align: center;
    cursor: pointer;

    &:last-child {
      margin-right: 0px;
    }

    &.active {
      color: var(--rx-btn-color);
      background-color: var(--rx-primary-color);
      border: 1px solid var(--rx-primary-color);
    }
  }
}

.dropdown-small {
  min-width: 5rem;
  font-size: 0.8rem;
}

// .calculateModal {
//   position: absolute;
//   top: 50px;
//   right: 25vw;
//   z-index: 999;

//   .modal-content {
//     width: 600px;
//   }
// }

.custom-tabs {
  display: flex;

  .tab-pills {
    margin-right: 16px;
    cursor: pointer;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      color: var(--rx-primary-color);
    }

    &.active {
      color: var(--rx-primary-color);
    }

    &.tab-pills-bar {
      border-bottom: 2px solid transparent;

      &:hover {
        border-bottom: 2px solid var(--rx-primary-color);
      }

      &.active {
        border-bottom: 2px solid var(--rx-primary-color);
      }
    }

    .dropdown-toggle {
      &.active {
        color: var(--rx-primary-color);
      }
    }

    .dropdown-item {
      font-size: 0.8rem;
      padding: 0.25rem 0.6rem;
      line-height: 20px;
    }

    .dropdown-menu {
      padding: 0px;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: var(--rx-btn-color);
      background-color: var(--rx-primary-color);
    }
  }

  &.custom-tabs-bar {
    border-bottom: 1px solid var(--rx-border-color);

    .tab-pills {
      line-height: 2rem;
    }
  }

  &.custom-tabs-button {
    .tab-pills {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 0px 1rem;
      margin-right: 5px;

      &.active {
        background: var(--rx-primary-color);
        color: var(--rx-btn-color);
        border-radius: 0.25rem;
      }
    }
  }
}

.rx-tbl {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .rx-tbl-row {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;

    .rx-tbl-col {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.15s ease-in-out;

      &.tbl-col-center {
        justify-content: center;
      }

      &.tbl-col-end {
        justify-content: flex-end;
      }
    }
  }

  &.rx-tbl-sm {
    .rx-tbl-col {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .rx-thead-bg {
    background: var(--rx-border-color);
  }

  &.rx-tbl-hover {
    .rx-tbody .rx-tbl-row {
      &:hover {
        background: var(--rx-table-hover);
      }
    }
  }

  &.rx-tbl-padded-sm {
    .rx-tbl-col {
      padding: 5px 10px;
    }
  }
}

.middle-table {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  height: 46px;
  align-items: center;
  line-height: 18px;
  border-top: 1px solid var(--rx-border-color);
  border-bottom: 1px solid var(--rx-border-color);

  .positionLeverageIndicator {
    display: flex;
    border-left: 1px solid var(--rx-border-color);
    margin-left: 5px;
    padding-left: 5px;

    .leverageIndicator {
      width: 5px;
      height: 12px;
      border: 1px solid var(--rx-border-color);
      margin-right: 2px;

      &:last-child {
        margin-right: 0px;
      }
    }

    .lvl1 {
      &.active {
        background-color: var(--success);
        border: 1px solid var(--success);
      }
    }

    .lvl2 {
      &.active {
        background-color: var(--success);
        border: 1px solid var(--success);
      }
    }

    .lvl3 {
      &.active {
        background-color: var(--warning);
        border: 1px solid var(--warning);
      }
    }

    .lvl4 {
      &.active {
        background-color: var(--warning);
        border: 1px solid var(--warning);
      }
    }

    .lvl5 {
      &.active {
        background-color: var(--danger);
        border: 1px solid var(--danger);
      }
    }
  }
}

.order-book-table {
  overflow: auto;
  height: calc(25vh - 86px);
  padding-right: 3px !important;
  margin-right: 2px;
}

.userDataTable {
  overflow: auto;
  height: calc(30vh - 57px);
}

.app-tv-chart-container {
  height: calc(75vh - 116px);
}

.app-tv-chart-container iframe {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.market-strip {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid var(--rx-border-color);

  .market-button {
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid var(--rx-border-color);
  }

  .current-market-panel {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;

    .market-panel-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding-left: 10px;
      border-right: 1px solid var(--rx-border-color);

      &:last-child {
        padding-right: 10px;
        border: 0px;
      }

      .item-1 {
        font-size: 10px;
        // text-align: center;
      }

      .item-2 {
        font-size: 11px;
        // text-align: center;
      }
    }
  }

  .chartsTab {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 100%;
    border-left: 1px solid var(--rx-border-color);
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color);
}

.dropdown-menu-small {
  min-width: 5rem;
}

.custom-btn {
  border-radius: 3px;
  border: 1px solid var(--rx-border-color);
  padding: 0px 5px;
  display: inline-block;
}

.mobileAppPanel {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--white);
  box-shadow: 0 -2px 8px rgba(18, 18, 20, 0.04);
  display: flex;
  gap: 12px;
  padding: 10px 20px;
  z-index: 1075;

  .appLink {
    background: var(--white);
    border: 1px solid var(--rx-primary-color);
    border-radius: 8px;
    font-size: 14px;
    color: #121214;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    gap: 8px;
    height: 50px;
    justify-content: center;
    width: 100%;

    &:hover {
      color: var(--rx-btn-color);
      background-color: var(--rx-primary-color);
      border-color: var(--rx-primary-color);
    }

    &.active {
      color: var(--rx-btn-color);
      background-color: var(--rx-primary-color);
      border-color: var(--rx-primary-color);
    }
  }
}

.rx-info-alert {
  padding: 5px 10px;
  color: var(--rx-btn-color);
  background-color: var(--rx-primary-color-light);
  border: 1px solid var(--rx-primary-color);
  border-radius: 0.25rem;
  margin-top: 10px;
}

.mb-10px {
  margin-bottom: 10px !important;
}

.notification-accordion {
  .accordion-toggle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &::before {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-right: 10px;
      line-height: 1.5rem;
      -moz-transition: all 0.1s linear;
      -webkit-transition: all 0.1s linear;
      transition: all 0.1s linear;
    }

    &.active-accordion {
      &::before {
        display: inline-block;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }

  .accordion-content {
    padding-left: 17.5px;
    display: flex;
    justify-content: space-between;

    img {
      width: auto;
      max-width: 100%;
    }
  }
}

.notify-panel {
  .notify-badge {
    position: absolute;
    top: -5px;
    right: -5px;
  }
}

.rx-zoom {
  overflow: hidden;
  margin: 0 auto;
  border-radius: 15px;
  cursor: pointer;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 5px 10px;
}

.rx-zoom img {
  width: 100%;
  transition: 0.5s all ease-in-out;
  border-radius: 15px;
}

.rx-zoom:hover img {
  transform: scale(1.2);
  border-radius: 15px;
}

.rx-product-background {
  height: 100%;
  width: 100%;
  text-align: center;
  border: 1px solid var(--rx-border-color);
  padding: 20px 0px;
}

.rx-accordion {
  .rx-accordion-panel {
    border-bottom: 1px solid var(--rx-border-color);
  }

  .rx-accordion-label {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    font-size: 1rem;
  }
}

.bb-1 {
  border-bottom: 1px solid var(--rx-border-color);
}

.floating-addon {
  position: absolute;
  top: 18px;
  right: 15px;
  border-left: 1px solid var(--rx-border-color);
  width: 100px;
  padding-left: 10px;
  overflow: hidden;
}

.green {
  color: var(--success);
}

.red {
  color: var(--danger);
}

.otp-input {
  border: 1px solid var(--rx-primary-color) !important;
}

.ellipsisData {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rxToastrCard {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  background-color: var(--rx-card-bg);
  color: var(--rx-text);
  padding: 25px;
  border-radius: 25px;
  z-index: 1250;
}

.rxToastrBg {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1200;
  background-color: #000 !important;
  opacity: 0.7;
}

@media only screen and (max-width: 360px) {
  .rxToastrCard {
    width: 90%;
  }
}

.rx-close {
  width: 100%;
  border-radius: 10px;
}

.rxIcons {
  .success-icon svg {
    fill: var(--success);
    width: 80px;
    height: 80px;
  }

  .info-icon svg {
    fill: var(--info);
    width: 80px;
    height: 80px;
  }

  .warning-icon svg {
    fill: var(--warning);
    width: 80px;
    height: 80px;
  }

  .danger-icon svg {
    fill: var(--danger);
    width: 80%;
    height: 80px;
  }
}

.nav-link-sm {
  display: block;
  padding: 0.25rem 0.5rem;
  font-size: 0.765rem;
}

.mv-list {
  overflow: auto;
  height: 50vh;
}

.mx-tabs {
  border: 1px solid var(--rx-border-color);
  border-radius: 0.25rem;
  padding: 10px 15px;
  justify-content: center;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #c79940;
  background-color: #c79940;
}

@media only screen and (max-width: 768px) {
  .mv-h1 {
    font-size: 2rem;
    line-height: 1.2;
  }

  .mv-h2 {
    font-size: 1.5rem;
    line-height: 1.2;
  }

  .mv-h3 {
    font-size: 1.3rem;
    line-height: 1.2;
  }

  .mv-h4 {
    font-size: 1.2rem;
    line-height: 1.2;
  }

  .mv-h5 {
    font-size: 1rem;
    line-height: 1rem;
  }

  .mv-h6 {
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
}

.theme-green .bs-datepicker-head {
  background-color: var(--rx-primary-color);
}

.theme-green .bs-datepicker-body table td.week span {
  color: var(--rx-primary-color-light);
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: var(--rx-primary-color);
}

.flex-gap-3 {
  gap: 1rem !important;
}

.flex-gap-2 {
  gap: 0.5rem !important;
}

.ace-tm .ace_print-margin {
  width: 0px !important;
  background: transparent !important;
}
.ace_scroller {
  left: 0 !important;
}
.ace-tm .ace_gutter {
  display: none;
}

.ace-tm .ace_cursor {
  color: var(--rx-border-color);
}

.md-editor-container .md-layout .preview-container .preview-panel {
  height: 100%;
  border-left: 1px solid var(--rx-border-color) !important;
  background-color: var(--rx-bg-default) !important;
  padding: 10px;
}

.md-editor-container .md-layout .tool-bar {
  background-color: var(--rx-primary-color)!important;
  border-bottom: 1px solid var(--rx-primary-color)!important;
}

.md-editor-container {
  position: relative;
  height: 100%;
  margin-bottom: 15px;
  border: 1px solid var(--rx-primary-color) !important;
}

.md-editor-container .md-layout .tool-bar .btn {
  color: var(--white) !important;
}

.ace-tm {
  background-color: var(--rx-bg-default) !important;
  color: var(--rx-text) !important;
}

.table-bordered td, .table-bordered th {
  border: 1px solid var(--rx-border-color);
  color: var(--rx-text);
}

.rx-limitBar {
  height: 16px;
  width: 100%;
  display: flex;
  position: relative;
  transition: all 0.5s ease 0s;
}

.rx-limitBar .limitTotal {
  position: relative;
  transition: all 0.5s ease 0s;
  border-radius: 2px;
}

.rx-limitBar .limitUsed {
  text-align: right;
  transition: all 0.5s ease 0s;
  border-radius: 2px;
}

.rx-limitBar .limitTotal:before {
  content: "";
  left: -3px;
  position: absolute;
  top: -5px;
  width: 6px;
  height: 25px;
  transform-origin: bottom left;
  background: #ffffff;
  min-width: auto;
  border-radius: 2px;
  transition: all 0.5s ease 0s;
}
